import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import SectionPaddingContext from '../../context/SectionPaddingContext';

const useStyles = makeStyles((theme) => ({
  sectionWhite: {
    position: 'relative',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

export const SectionWhite = ({
  top = false,
  bottom = false,
  extraTop = false,
  extraBottom = false,
  additionalTop = 0,
  additionalBottom = 0,
  marginTop = '0px',
  marginBottom = '0px',
  homeIntro,
  zIndex,
  children,
}) => {
  const classes = useStyles();

  const { paddingTop, paddingTop2, paddingBottom, paddingBottom2 } = useContext(
    SectionPaddingContext
  );

const [windowWidth, setWindowWidth] = useState(null);
    // Check if window is defined and only run on client-side
    useEffect(() => {
      if (typeof window !== 'undefined') {
        setWindowWidth(window.innerWidth); // Only set window width after mount
      }
    }, []); // Empty dependency array ensures this effect runs only once on mount
  
    if (windowWidth === null) {
      return null; // Prevent rendering until windowWidth is set
    }
  
  const finalPaddingTop = extraTop 
  ? paddingTop2
    : top
    ? paddingTop
    : homeIntro
    ? windowWidth > 1280 ? paddingTop * 0.5 : paddingTop
    : 0;
  const finalPaddingBottom = extraBottom
    ? paddingBottom2
    : bottom
    ? paddingBottom
    : 0;
  return (
    <div
      className={`${classes.sectionWhite}`}
      style={{
        paddingTop: `${finalPaddingTop + additionalTop}px`,
        paddingBottom: `${finalPaddingBottom + additionalBottom}px`,
        marginTop,
        marginBottom,
        zIndex: zIndex ? zIndex : null,
      }}
    >
      {children}
    </div>
  );
};
