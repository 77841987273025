import React from 'react';
import { Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EmailForm } from '../ContactForm/EmailForm';
import { ImgRight } from './ImgRight';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    [theme.breakpoints.down('xl')]: {},
    [theme.breakpoints.down('lg')]: {},
    [theme.breakpoints.down('md')]: {},
  },

  heroContainerCentered: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
    },
  },
  GeneralHeroHeading: {
    color: '#fff',
    textAlign: 'left',
    maxWidth: '730px',
    fontSize: '3.6rem',
    fontWeight: 700,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.75rem',
      maxWidth: '600px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4rem',
    },
  },
  GeneralHeroHeadingCentered: {
    textAlign: 'center',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  GeneralHeroSubheading: {
    color: '#fff',
    width: '100%',
    textAlign: 'left',
    fontSize: '1.55rem',
    margin: '0',
    marginTop: '1rem',
    lineHeight: '1.2',
    maxWidth: '730px',
    fontFamily: 'Roboto, sans-serif',
  },
  GeneralHeroSubheadingCentered: {
    textAlign: 'center',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  trademark: {
    fontSize: '1rem',
  },
  emailFormContainer: {
    marginTop: '2rem',
    width: '100%',
    maxWidth: '470px',
  },
  logoContainer: {
    // maxWidth: '350px',
    // maxHeight: '90px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '200px',
    },
  },
}));

export const GeneralHero = ({ heroData = {} }) => {
  const classes = useStyles();
  const { _rawContent, heroImage, showEmailForm, softwareLogo } = heroData;

  const isCentered = !heroImage;

  // its filtering the _rawContent array to remove any content blocks that are empty or don't contain meaningful text. - BG
  // const filteredContent = _rawContent?.filter(
  //   (block) =>
  //     block.children && block.children.some((child) => child.text.trim() !== '')
  // );

  const md = useMediaQuery('(max-width: 960px)');

  return (
    <Container
      style={{ position: 'relative', zIndex: 3 }}
      className={
        isCentered ? classes.heroContainerCentered : classes.heroContainer
      }
    >
      {softwareLogo ? (
        <div className={classes.logoContainer}>
          <GatsbyImage
            imgStyle={{ objectFit: 'contain' }}
            style={{ marginBottom: '.5rem' }}
            image={softwareLogo.asset.gatsbyImageData}
          />
        </div>
      ) : null}
      <PortableText
        content={_rawContent}
        serializers={{
          h1: (props) => (
            <Typography
              variant='h1'
              className={`${classes.GeneralHeroHeading} ${
                isCentered ? classes.GeneralHeroHeadingCentered : ''
              }`}
            >
              {props.children}
            </Typography>
          ),
          normal: (props) => (
            <Typography
              variant='body1'
              className={`${classes.GeneralHeroSubheading} ${
                isCentered ? classes.GeneralHeroSubheadingCentered : ''
              }`}
              component='p'
            >
              {props.children}
            </Typography>
          ),
          strong: (props) => (
            <strong style={{ color: '#63CDE5' }}>{props.children}</strong>
          ),
          sup: (props) => (
            <sup className={classes.trademark}>{props.children}</sup>
          ),
          default: (props) => <div>{props.children}</div>,
        }}
      />
      {showEmailForm && (
        <div
          className={`${classes.emailFormContainer} ${
            isCentered ? classes.emailFormCentered : ''
          }`}
        >
          <EmailForm />
        </div>
      )}
      {heroImage && !md && <ImgRight heroImage={heroImage} />}
    </Container>
  );
};
