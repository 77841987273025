import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import { SectionBlue } from '../components/Sections/SectionBlue';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { GeneralHero } from '../components/General/GeneralHero';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { CustomerLogos } from '../components/CustomerLogos';
import { IntroStats } from '../components/Home/Intro/IntroStats';
import { SectionWaveDefault } from '../components/Waves/SectionWaveDefault';
import { Resources } from '../components/General/Resources';
import { SEO } from '../components/SEO';

import LocaleContext from '../context/LocaleContext';

import Form from '../components/ContactForm/Form';

const ContactUsPage = ({ data: { contactUs }, location }) => {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const {
    hero,
    _rawByTheNumbersHeader,
    byTheNumbers,
    byTheNumbersDisclaimer,
    noStatsCta,
    contactForm,
    pardotUrl,
    _rawCustomerLogosHeader,
    customerLogos,
    resourceTitle,
    resources,
    _rawResourceBody,
    metaTitle,
    metaDescription,
  } = contactUs;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />

      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      <SectionWhite>
        <Form
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          location={location}
          slideUp
        />
      </SectionWhite>
      {customerLogos && (
        <SectionWhite top bottom>
          <CustomerLogos
            customerLogosArray={customerLogos}
            customerLogosHeader={_rawCustomerLogosHeader}
          />
        </SectionWhite>
      )}
      <SectionBlue top extraBottom>
        <IntroStats
          header={_rawByTheNumbersHeader}
          stats={byTheNumbers}
          disclaimer={byTheNumbersDisclaimer}
          noStatsCta={noStatsCta}
        />
        <SectionWaveDefault
          flipHorizontal={true}
          flipVertical={true}
          bottom='-1px'
          fillColorShape='#FFF'
        />
      </SectionBlue>
      <SectionWhite zIndex={3}>
        {/* <SectionWaveDefault top='0px' fillColorBorder='#FFF' /> */}
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contactUs: sanityContactUs(slug: { current: { eq: $slug } }) {
      metaTitle
      metaDescription
      title
      slug {
        current
      }
      hero {
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        showEmailForm
      }
      _rawByTheNumbersHeader
      byTheNumbers {
        number
        unit
        _rawDescription
        icon
      }
      byTheNumbersDisclaimer
      noStatsCta {
        ctaBackground {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaHeader
        _rawCtaBlockHeader
        ctaLink
        ctaSubheader
        ctaText
        internalLink
      }
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        title
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
    }
  }
`;

export default ContactUsPage;
