import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  heroImgBg: {
    position: 'absolute',
    right: '-120px',
    top: '55%',
    transform: 'translateY(-50%)',
    width: '600px',
    height: 'auto',
    objectFit: 'cover',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      width: '500px',
      right: '5px',
      top: '55%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      position: 'relative',
      margin: '2rem auto 0 auto',
      transform: 'translateY(0)',
      top: 0,
      right: 0,
      letf: 0,
    },
  },
}));

export const ImgRight = ({ heroImage, customClass = '' }) => {
  const classes = useStyles();

  return (
    <GatsbyImage
      image={heroImage?.asset?.gatsbyImageData}
      alt={heroImage.alt || 'Hero image'}
      className={`${classes.heroImgBg} ${customClass}`}
    />
  );
};
