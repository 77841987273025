import React from 'react';
import PortableText from 'react-portable-text';

import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Resource } from './Resource';


const useStyles = makeStyles((theme) => ({
	header: {
		//fontWeight: 700,
		color: theme.workwaveBlue,
		textAlign: 'center',
    marginBottom: '1rem',
		[theme.breakpoints.down('sm')]: {
		  textAlign: 'left',
		},
	},
	subheader: {
		textAlign: 'center',
    //color: theme.workwaveBlue,
    margin: '0 auto 3rem auto',
    lineHeight: '1.2',
    maxWidth: '1080px',
		[theme.breakpoints.down('sm')]: {
		  textAlign: 'left',
		},
	},
}));

export const Resources = ({ header, subheader, resources }) => {
	const classes = useStyles();
	const sm = useMediaQuery('(max-width: 600px)');

	return (
    <Container>
      <Typography variant='h2' className={classes.header}>
        {header}
      </Typography>

      <PortableText
        content={subheader}
        serializers={{
          normal: ({ children }) => (
            <Typography variant='body1' className={classes.subheader}>
              {children}
            </Typography>
          ),
          code: () => <br style={{ display: sm ? 'none' : 'block' }} />,
        }}
      />

      <Grid container spacing={4} className={classes.gridContainer}>
        {resources.map((resource, index) => (
          <Resource key={index} resource={resource} />
        ))}
      </Grid>
    </Container>


		// <div style={{ padding: noWave ? '4rem 0 0 0' : '0', zIndex: 100 }}>
		// 	<Grid
		// 		container
		// 		item
		// 		direction='column'
		// 		justifyContent='center'
		// 		alignItems='center'
		// 		spacing={1}>
		// 		<Grid item>
		// 			<Typography variant='h2' className={classes.header}>
		// 				{header}
		// 			</Typography>
		// 		</Grid>
		// 		<Grid item xs={12} md={6}>
		// 			<PortableText
		// 				content={subheader}
		// 				// className={classes.text}
		// 				serializers={{
		// 					normal: ({ children }) => (
		// 						<Typography variant='body1' className={classes.subheader}>
		// 							{children}
		// 						</Typography>
		// 					),
		// 					code: () => <br style={{ display: sm ? 'none' : 'block' }} />,
		// 				}}
		// 			/>
		// 		</Grid>
		// 	</Grid>
		// 	<Grid
		// 		container
		// 		direction='row'
		// 		justifyContent='center'
		// 		style={{ padding: '2rem 0' }}>
		// 		{resources.map((resource, index) => (
		// 			<Resource key={index} resource={resource} />
		// 		))}
		// 	</Grid>
		// </div>
	);
};
