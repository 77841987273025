import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  outer: {
    width: '100%',
    overflow: 'hidden',
    // Additional positioning is applied inline.
  },
  svgContainer: {
    display: 'flex',
    width: '100%',
    // Set the container height based on breakpoints:
    [theme.breakpoints.up('lg')]: {
      height: 150,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: 125,
    },
    [theme.breakpoints.down('md')]: {
      height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      height: 80,
    },
  },
  // Left SVG: Natural ratio is 289 / 150 ≈ 1.92667.
  leftSvg: {
    flexShrink: 0,
    height: '100%',
    marginRight: '-1px',
    width: 'auto',
  },
  // Right SVG: Fills the remaining space.
  rightSvg: {
    flexGrow: 1,
    height: '100%',
    width: 'auto',
    marginRight:' -1px',
  },
}));

export const SectionWaveDefault = ({
  fillColorShape = '#F5F9FF', // default shape color
  fillColorBorder = '#63CDE5', // default border color
  flipHorizontal = false,
  flipVertical = false,
  top = null,
  bottom = null,
  zIndex = '2',
  position = 'absolute',
}) => {
  const classes = useStyles();

  // Build transform string based on flip props.
  const transformStr = `${flipHorizontal ? 'scaleX(-1)' : ''} ${flipVertical ? 'scaleY(-1)' : ''}`.trim();

  // Inline positioning styles (with optional top, bottom, and transform).
  const positionStyles = {
    zIndex,
    position,
    ...(top !== null ? { top } : {}),
    ...(bottom !== null ? { bottom } : {}),
    ...(transformStr ? { transform: transformStr } : {}),
  };

  return (
    <div className={classes.outer} style={positionStyles}>
      <div className={classes.svgContainer}>
        {/* Left SVG with fixed aspect ratio */}
        <svg
          className={classes.leftSvg}
          viewBox="0 0 289 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.000247986 22.5943C88.753 119.624 152.393 150.647 288.7 149.992V0.000244141L0.000244141 0.000244141L0.000247986 22.5943Z"
            fill={fillColorBorder}
          />
          <path
            d="M3.47945e-07 2.04471C88.7527 99.0741 152.392 130.097 288.699 129.442V0L0 5.18608e-05L3.47945e-07 2.04471Z"
            fill={fillColorShape}
          />
        </svg>
        {/* Right SVG that fills remaining width */}
        <svg
          className={classes.rightSvg}
          viewBox="0 0 1152 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            d="M0.000976562 149.992H1151.3V0.000244141L0.000976562 0.000244141V149.992Z"
            fill={fillColorBorder}
          />
          <path
            d="M0 129.442H1151.3V0L0 0.000206815V129.442Z"
            fill={fillColorShape}
          />
        </svg>
      </div>
    </div>
  );
};
