import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    borderRadius: '20px',
    background: '#fff',
    border: '2px solid #E5EAF4',
    boxShadow: '0 6px 40px 0px rgba(0, 0, 0, 0.15)',
    transition: 'transform 0.3s ease',
    '&:hover $arrow': {
      transform: 'translateX(10px)',
    },
    '&:hover $image': {
      transform: 'scale(1.1)',
    },
  },
  imageWrapper: {
    width: '100%',
    height: '300px',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '200px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
  },
  contentBox: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  contentBoxHeading: {
    color: '#002d5c',
    fontSize: '1.75rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  contentBoxBlurb: {
    margin: '16px 0',
  },
  arrow: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    transition: 'transform 0.3s ease',
  },
  equalHeight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    textDecoration: 'none',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: '"Poppins", sans-serif',
    fontSize: '1.2rem',
    fontWeight: '600',
    color: '#002d5c',
    justifyContent: 'flex-start',
    // textTransform: 'capitalize', // This was causing eBook to be EBook
  },
}));

export const Resource = ({ resource }) => {
  const classes = useStyles();
  //const sm = useMediaQuery('(max-width: 715px)');
  return (
    <Grid
      item
      xs={12}
      md={6}
      className={classes.equalHeight}
      component='a'
      href={resource.ctaLink}
      target='_blank'
    >
      <Box className={classes.gridItem}>
        <Box className={classes.imageWrapper}>
          <GatsbyImage
            image={resource.image?.asset?.gatsbyImageData}
            alt='resource image'
            className={classes.image}
          />
        </Box>
        <Box className={classes.contentBox}>
          <Typography variant='h3' className={classes.contentBoxHeading}>
            {resource.title}
          </Typography>
          <Typography className={classes.contentBoxBlurb} variant='body1'>
            {resource.blurb}
          </Typography>
          <div
            href={resource.ctaLink}
            target='_blank'
            color='primary'
            underline='none'
            className={classes.link}
          >
            {resource.ctaText}
            <ArrowForwardIcon className={classes.arrow} />
          </div>
        </Box>
      </Box>
    </Grid>
  );
};
