import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Container, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#002d5c',
    textAlign: 'center',
  },
  pinkHighlight: {
    color: '#D10066',
  },
  logoGrid: {
    marginTop: theme.spacing(5),
    marginBottom: '-20px',
  },
  logoItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0),
  },
  logoImage: {
    width: '90%',
    maxWidth: '100%',
    height: 'auto',
  },
}));

export const CustomerLogos = ({ customerLogosArray, customerLogosHeader }) => {
  const classes = useStyles();
  return (
    <Container>
      {!!customerLogosHeader ? (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} lg={10}>
            <PortableText
              content={customerLogosHeader}
              serializers={{
                h2: ({ children }) => (
                  <Typography
                    variant='h2'
                    color='primary'
                    className={classes.header}
                  >
                    {children}
                  </Typography>
                ),
                strong: ({ children }) => (
                  <span className={classes.pinkHighlight}>{children}</span>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid container className={classes.logoGrid}>
        {customerLogosArray.map((customerLogo, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            className={classes.logoItem}
            key={index}
          >
            <GatsbyImage
              image={customerLogo.logo?.asset.gatsbyImageData}
              alt={`Logo ${index + 1}`}
              className={classes.logoImage}
              quality={100}
              imgStyle={{ objectFit: 'contain' }}
              //THIS IS WHERE THE IMAGES ARE TOO BIG
              // style={{ width: '425px' }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>

    // <div style={{ padding: '3rem 0 0' }}>
    // 	{!!customerLogosHeader ? (
    // 		<Grid
    // 			container
    // 			direction='row'
    // 			justifyContent='center'
    // 			alignItems='center'
    // 			style={{ padding: '2rem 0' }}>
    // 			<Grid item xs={12}>
    // 				<Typography
    // 					variant='h2'
    // 					color='primary'
    // 					style={{
    // 						fontWeight: 600,
    // 						textAlign: 'center',
    // 					}}>
    // 					{customerLogosHeader}
    // 				</Typography>
    // 			</Grid>
    // 		</Grid>
    // 	) : null}
    // 	<Grid
    // 		// style={{
    // 		// 	padding: '1rem 0',
    // 		// 	border: '1px solid #fffff',
    // 		// 	boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
    // 		// 	borderRadius: '8px',
    // 		// }}
    // 		container
    // 		direction='row'
    // 		justifyContent='space-around'
    // 		alignItems='center'
    // 		spacing={2}>
    // 		{customerLogosArray.map((customerLogo, index) => (
    // 			<Grid
    // 				container
    // 				direction='row'
    // 				justifyContent='center'
    // 				item
    // 				xs={12}
    // 				sm={6}
    // 				md={3}
    // 				key={index}>
    // 				<GatsbyImage
    // 					image={customerLogo.logo?.asset.gatsbyImageData}
    // 					alt=''
    // 					//THIS IS WHERE THE IMAGES ARE TOO BIG
    // 					// style={{ width: '425px' }}
    // 				/>
    // 			</Grid>
    // 		))}
    // 	</Grid>
    // </div>
  );
};
